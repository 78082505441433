import React, { useState, useEffect, useRef } from 'react';
import { countries } from 'country-data';

import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import bg_pricing from '../../assets/img/landingPage/fondo.png'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { Alert } from 'react-bootstrap';
import { message } from 'antd';




function ContactSalesForm() {
    const [fullName, setFullName] = useState('');
    const [company, setCompany] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [workEmail, setWorkEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [alerta, setAlerta] = useState('');

    const [errors, setErrors] = useState({
        fullName: '',
        company: '',
        jobTitle: '',
        workEmail: '',
        country: '',
        phoneNumber: ''
    });

    const dropdownRef = useRef(null);

    const handlePhoneNumber = (e)=>{

        const contieneLetras = /[a-zA-Z]/.test(e.target.value);
        if (!contieneLetras && e.target.value.length<13) setPhoneNumber(e.target.value);

    }

    const normalize = (str) =>
        str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();

    // Añadir filtrado de países válidos como en contacto
    const validCountries = countries.all.filter(country => 
        country && 
        country.name && 
        typeof country.name === 'string' && 
        country.name.trim() !== ''
    );

    const handleCountryInputChange = (e) => {
        const value = e.target.value.trim();
        setSearchTerm(value);
        
        if (value === '') {
            setFilteredCountries([]);
            setShowDropdown(false);
            return;
        }

        // Filtrar países que empiecen con el valor ingresado
        const normalizedSearch = normalize(value);
        const filtered = validCountries.filter(country => 
            normalize(country.name).startsWith(normalizedSearch)
        );
        
        setFilteredCountries(filtered);
        setShowDropdown(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside, true);
        document.body.classList.add('dark-bg');

        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
            document.body.classList.remove('dark-bg');
        };
    }, []);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const re = /^\+?[\d\s-()]+$/;
        return re.test(phone);
    };

    const validateForm = () => {
        let tempErrors = {
            fullName: '',
            company: '',
            jobTitle: '',
            workEmail: '',
            country: '',
            phoneNumber: ''
        };
        let isValid = true;

        if (!fullName.trim()) {
            tempErrors.fullName = 'Full name is required';
            isValid = false;
        }

        if (!company.trim()) {
            tempErrors.company = 'Company is required';
            isValid = false;
        }

        if (!jobTitle.trim()) {
            tempErrors.jobTitle = 'Job title is required';
            isValid = false;
        }

        if (!workEmail.trim()) {
            tempErrors.workEmail = 'Email is required';
            isValid = false;
        } else if (!validateEmail(workEmail)) {
            tempErrors.workEmail = 'Please enter a valid email';
            isValid = false;
        }

        if (!searchTerm.trim()) {
            tempErrors.country = 'Country is required';
            isValid = false;
        }

        if (!phoneNumber.trim()) {
            tempErrors.phoneNumber = 'Phone number is required';
            isValid = false;
        } else if (!validatePhoneNumber(phoneNumber)) {
            tempErrors.phoneNumber = 'Please enter a valid phone number';
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                await ObexRequestHandler.post('/feedback', {
                    fullname: fullName,
                    company,
                    jobtitle: jobTitle,
                    email: workEmail,
                    country: searchTerm,
                    phone: phoneNumber,
                    message: 'Demo request'
                });
                
                // Limpiar el formulario después del envío exitoso
                setFullName('');
                setCompany('');
                setJobTitle('');
                setWorkEmail('');
                setSearchTerm('');
                setPhoneNumber('');
                
                console.log('Demo request sent successfully!');

                setAlerta('Demo request sent successfully!')


                window.open('https://calendly.com/raulnogales/avapdemo', '_NOTARGET');


                setTimeout(() => { 
                    setAlerta('');
                  }, 2000);

            } catch (error) {
                console.error('Error sending the demo request:', error);
                alert('There was an error sending the demo request. Please try again.');
            }
        }
    };

    const handleCountrySelect = (countryName) => {
        setSearchTerm(countryName);
        setShowDropdown(false);
    };

    return (
        <>
            <FlNav />
            <div className="w-100 contactWrapper bg-hexagons" style={{ backgroundImage: `url(${bg_pricing})`, backgroundColor: '#0e1213' }}>
                <div className="fl-contact-container">
                    <h1>Get Your AVAP Demo</h1>
                    <p>
                        Fill out the form to request a personalized demo and see how AVAP can streamline your API development.<br />
                        Our team will reach out to schedule a session tailored to your needs.
                    </p>

                    <form onSubmit={handleSubmit} className="fl-contact-form">
                        <div>
                            <label>Full Name *</label>
                            <input
                                type="text"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                placeholder="John Doe"
                                className={errors.fullName ? 'error' : ''}
                            />
                            {errors.fullName && <span className="error-message">{errors.fullName}</span>}
                        </div>

                        <div>
                            <label>Company *</label>
                            <input
                                type="text"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder="Company Name"
                                className={errors.company ? 'error' : ''}
                            />
                            {errors.company && <span className="error-message">{errors.company}</span>}
                        </div>

                        <div className="fl-contact-row">
                            <div>
                                <label>Job Title *</label>
                                <input
                                    type="text"
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    placeholder="Ex: Marketing Manager"
                                    className={errors.jobTitle ? 'error' : ''}
                                />
                                {errors.jobTitle && <span className="error-message">{errors.jobTitle}</span>}
                            </div>

                            <div>
                                <label>Work email *</label>
                                <input
                                    type="email"
                                    value={workEmail}
                                    onChange={(e) => setWorkEmail(e.target.value)}
                                    placeholder="you@company.com"
                                    className={errors.workEmail ? 'error' : ''}
                                />
                                {errors.workEmail && <span className="error-message">{errors.workEmail}</span>}
                            </div>
                        </div>

                        <div className="fl-contact-row">
                            <div className="fl-contact-dropdown" ref={dropdownRef}>
                                <label>Country *</label>
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={handleCountryInputChange}
                                    onFocus={() => {
                                        if (searchTerm.trim() !== '') {
                                            const filtered = validCountries.filter(country => 
                                                normalize(country.name).startsWith(normalize(searchTerm))
                                            );
                                            setFilteredCountries(filtered);
                                            setShowDropdown(true);
                                        } else {
                                            const filtered = validCountries.filter(country => 
                                                normalize(country.name).startsWith(normalize(''))
                                            );
                                            setFilteredCountries(filtered);
                                            setShowDropdown(true);
                                        }
                                    }}
                                    placeholder="Choose your country"
                                    className={errors.country ? 'error' : ''}
                                />
                                {errors.country && <span className="error-message">{errors.country}</span>}
                                {showDropdown && filteredCountries.length > 0 && (
                                    <ul className="fl-contact-dropdown-list">
                                        {filteredCountries.map((country) => (
                                            <li
                                                key={country.alpha2}
                                                onClick={() => handleCountrySelect(country.name)}
                                            >
                                                {country.name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div>
                                <label>Phone Number *</label>
                                <input
                                    type="tel"
                                    value={phoneNumber}
                                    onChange={(e) => handlePhoneNumber(e)}
                                    placeholder="+1 (234) 567-8900"
                                    className={errors.phoneNumber ? 'error' : ''}
                                />
                                {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
                            </div>
                        </div>

                        <div className="d-flex justify-content-end">
                            <button type="submit" className="fl-contact-submit-btn">
                                Request Demo
                            </button>
                        </div>
                    </form>
                    {alerta.length > 0 && (
                    <Alert variant='success' style={{ marginTop:'1em' }}>
                      {alerta}
                    </Alert>)}
                </div>
            </div>
            <FlFooter />
        </>
    );
}

export default ContactSalesForm;
