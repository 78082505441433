import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import dashboard from '../../assets/img/landingPage/commander/Comander-home.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/commander/Commander-01.png'
import features_2 from '../../assets/img/landingPage/commander/Commander_02.png'
import features_3 from '../../assets/img/landingPage/commander/Commander_03.png'



import icn_1 from '../../assets/img/landingPage/icn_vision.png'
import icn_2 from '../../assets/img/landingPage/icn_mission.png'
import icn_3 from '../../assets/img/landingPage/icono1.png'

import rocket from '../../assets/img/landingPage/rocket.png'
import tablet from '../../assets/img/landingPage/tabletApi.png'

import logo from '../../assets/img/logos/AVAP_Commander_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import frameTablet from '../../assets/img/landingPage/iPad_16.9.png'

import lg_cloud from '../../assets/img/logos/AVAP_Cloud_negativo.svg'



const CloudProduct = () => {

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);


    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">The Frontline of API Lifecycle Management</h1>
                    <p className="fl-subtitle">
                        Unified interface, seamless integration, <br /> and enhanced collaboration
                    </p>
                    <a className="glowBtn" href="https://avapcloud.com/business_en" target='_blank'>
                        <span className="btnInner">   AVAP Cloud Start for Free</span>
                    </a>
                </section>

                {/* Powerful API Platform */}
                <section className="text-center py-5">
                    <img
                        src={dashboard}
                        alt="Platform Placeholder"
                        className="img-fluid mt-4 tabletWidth"
                    />
                    {/*   <h2 className="fl-title  fl-m-n"></h2> */}
                    <p className="fl-subtitle-xs mb-3">
                        Streamlined API Management Across Cloud and On-Premise Environments
                    </p>
                    <p className="fl-text">
                        AVAP Commander provides a unified dashboard for managing the full API lifecycle across AVAP Cloud or at on-premise environments. Powered by AI and virtuality, it ensures seamless API creation, deployment, and optimization.

                    </p>

                </section>

                {/* Features */}
                {/*    <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>On-premise Infrastructure</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Universal Platform</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Database Control</p>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Unified Dashboard for Comprehensive Management</h3>
                            <p className="fl-text">
                                AVAP Commander provides a central dashboard that integrates all aspects of API lifecycle management. View API projects, requests, storage, API publishers, and developer portals all in one place for easy, streamlined access to critical data and actions.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Seamless Cloud and On-Premise Integration</h3>
                            <p className="fl-text">
                                The platform offers a seamless experience across both cloud and on-premise environments, allowing users to manage APIs, monitor performance, and execute deployments regardless of their infrastructure.
                            </p>
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Advanced Backend API Support</h3>
                            <p className="fl-text">
                                AVAP Commander enhances backend API management by enforcing security, ensuring consistency, and optimizing
                                performance—all within your private infrastructure. It allows seamless integration with other APIs while
                                providing the flexibility to adjust consumption interfaces and response formats. Additionally, it offers
                                advanced capabilities such as analytics, access control, and monetization, tailored to on-premise deployments.
                            </p>
                        </div>
                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>

                {/* Key Benefits */}
                <section className="pb-5">
                    <h2 className="fl-title text-center">Key Benefits</h2>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="fl-card grayGradient">
                                    <img src={icn_1} alt="" />
                                    <h4 className="fl-subtitle">Centralized Control for Efficient Management</h4>
                                    <p className="fl-text">
                                        Easily manage and monitor all aspects of the API lifecycle in one unified platform, saving time and reducing complexity.
                                    </p>
                                    <img className="rocket" src={rocket} alt="" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="fl-card grayGradient">
                                            <img src={icn_2} alt="" />
                                            <h4 className="fl-subtitle">Enhanced Collaboration Across Teams</h4>
                                            <p className="fl-text pb-c">
                                            AVAP Commander fosters smoother collaboration among development teams by offering a clear, shared view of the API lifecycle, from creation to deployment.

                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="fl-card grayGradient">
                                            <img src={icn_3} alt="" />
                                            <h4 className="fl-subtitle">Improved API Performance and Optimization</h4>
                                            <p className="fl-text pb-c">
                                            Real-time monitoring and insights allow for proactive performance optimization and quick issue resolution, ensuring APIs are always running at peak efficiency.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                    <section className="container py-5">
                        <div className="row align-items-center mb-5">
                            <div className="col-md-6">
                                {/* <h2 className="fw-bold">Key Benefits</h2> */}
                                <h3 className="fw-bold mb-5">Getting Started</h3>
                                <ul className="startedList">
                                    <li>   <img src={user} className="mr-2" alt="" />
                                        <a href="https://avapid.com/" target='_blank'>Create your AVAP ID</a>
                                    </li>

                                    <li> <img src={download} className="mr-2" alt="" />
                                        <a href="https://developer.avapframework.com/wiki/over_view12-GET%20STARTED_Downloads/0" target='_blank'>Download basic software</a>

                                    </li>
                                    <li>
                                        <img src={key} className="mr-2" alt="" />
                                        <a href="https://developer.avapframework.com/wiki/over_view_aefmvsc_cli-GET%20STARTED_Dev_Token/0" target='_blank'>Create your Developer Token</a>
                                    </li>
                                    <li>
                                        <img src={doc} className="mr-2" alt="" />
                                        <a href="https://developer.avapframework.com/wiki/over_view_cloud-GET%20STARTED_Projects/0" target='_blank'>Create your first API Project</a>
                                    </li>
                                </ul>
                                <a className="glowBtn-dark m-0" target='_blank' href="https://developer.avapframework.com"><span className="btnInner">  Developer Portal →</span></a>
                            </div>
                            <div className="col-md-6 ">
                                <div className="ytWrapper2">
                                    <img src={frameTablet} alt="" className="frameTablet" />
                                    <iframe className='fl-video-container' src="https://www.youtube.com/embed/LlVcKzcM3uc?si=RbgB1V_dJPcusm5y&amp;controls=0&rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                                </div>
                            </div>
                        </div>
                    </section>


                </div>




            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default CloudProduct;
