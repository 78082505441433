import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/connectors/Connectors_01.png'
import features_2 from '../../assets/img/landingPage/connectors/Connectors_02.png'
import features_3 from '../../assets/img/landingPage/connectors/Connectors_03.png'




import logo from '../../assets/img/logos/AVAP_Connector_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Unlock effortless integration</h1>
                    <p className="fl-subtitle">
                        Boost your API-driven applications
                    </p>
                          <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <p className="fl-subtitle mb-3">
                                Titulo. Lorem ipsum.
                            </p> */}
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                            AVAP Pre-configured components



                            </p>
                            <p className="fl-subtitle-xs">
                            Effortless integration and seamless connectivity for your API-driven applications


                            </p>
                            <p className="fl-text">
                            AVAP Connectors simplify integration with external systems, platforms, and databases. They streamline data exchange and enhance connectivity, enabling developers to accelerate their projects by focusing on core functionalities instead of integration complexities. With universal compatibility, robust security, and seamless data exchange, AVAP Connectors elevate API-driven applications to new levels of efficiency and interoperability.
                            </p>
                        </div>
                    </div>

                </section>

                {/* Features */}
                <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Streamline data exchange</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Enhance Connectivity</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Universal Compatibility</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Pre-Configured Integration Components</h3>
                            <p className="fl-text">
                                AVAP Connectors are ready-to-use tools that eliminate the need for manual setup or complex coding. This reduces integration time, allowing developers to implement third-party system connections with minimal effort.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                        <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Universal Compatibility</h3>
                            <p className="fl-text">
                                Designed to work seamlessly with various external systems, platforms, and databases, AVAP Connectors offer unmatched flexibility, enabling integration with a wide range of technologies regardless of the stack or environment.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Secure and Seamless Data Exchange</h3>
                            <p className="fl-text">
                                AVAP Connectors ensure smooth and efficient data transfer while incorporating robust security measures, including encryption protocols, to safeguard sensitive information during interactions with external systems.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Accelerated Development
                                </p>
                                <p className="fl-text">
                                    By eliminating integration complexities, AVAP Connectors save time and allow developers to focus on building innovative features, speeding up project timelines.
                                    <br />&nbsp;
                                    <br />&nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Interoperability
                                </p>
                                <p className="fl-text">
                                    Boost connectivity across diverse platforms and systems, improving the overall functionality and usability of API-driven applications.
                                    <br />&nbsp;
                                    <br />&nbsp;
                                    <br />&nbsp;

                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Improved Scalability and Security
                                </p>
                                <p className="fl-text">
                                    AVAP Connectors support growing integration needs while maintaining high levels of data security, ensuring your applications stay reliable and protected as they expand.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
