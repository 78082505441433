import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/vc/VersionContro_01.png'
import features_2 from '../../assets/img/landingPage/vc/VersionContro_02.png'
import features_3 from '../../assets/img/landingPage/vc/VersionContro_03.png'



import logo from '../../assets/img/logos/AVAPSourceControl-negativo.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Efficient code repository and version tracking for APIs</h1>
                    <p className="fl-subtitle">
                        Track code evolution and easily identify version deployment <br /> across environments

                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <p className="fl-subtitle mb-3">
                                Titulo
                            </p> */}
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                                Simplified code management for API development
                            </p>
                            <p className="fl-text">
                                AVAP Source Control provides tools for creating and managing code repositories, tracking code evolution, and offering clear visibility of which code versions are deployed across different environments. It helps maintain organization and consistency.

                            </p>
                        </div>
                    </div>

                </section>

                {/* Features */}
                {/*            <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Centralized System</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Backward Compatibility</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Consistent Performance</p>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>
                        <div className="col-md-6 pr-5">

                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Code Repository Management</h3>
                            <p className="fl-text">
                                Easily create and manage code repositories for API development. Centralize all your project files in one place, enabling organized collaboration and version control.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Version Tracking</h3>
                            <p className="fl-text">
                                Integration with testing frameworks allows for automatic validation of changes, ensuring that updates are error-free before deployment. Automated deployment ensures smooth transitions between development, staging, and production environments, streamlining the release cycle and improving reliability.

                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>
                    </div>

                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>



                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Multi-Release Management and Backward Compatibility
                            </h3>
                            <p className="fl-text">
                            AVAP supports managing multiple API releases simultaneously, allowing developers to maintain backward compatibility while working on new releases. This flexibility ensures that both legacy and updated versions of APIs can coexist without issues, making it easier to support diverse client needs.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col mb-5">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Collaboration
                                </p>
                                <p className="fl-text">
                                    Multiple developers can work on different API releases simultaneously, boosting team collaboration and minimizing conflicts during development.
                                    <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Simplified Debugging
                                </p>
                                <p className="fl-text">
                                    The Source control system makes it easy to identify and resolve issues by keeping a history of changes and providing the ability to revert to previous stable versions when needed.

                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Backward Compatibility and Seamless Updates
                                </p>
                                <p className="fl-text">
                                    AVAP ensures backward compatibility with older releases, allowing developers to introduce new features without breaking functionality for existing clients.

                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
