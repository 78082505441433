import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/meshManager/MeshManager01.png'
import features_2 from '../../assets/img/landingPage/meshManager/MeshManager-02.png'
import features_3 from '../../assets/img/landingPage/meshManager/MeshManager03.png'



import logo from '../../assets/img/logos/AVAP_ManagementMesh_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Effortless Hybrid Mesh Management</h1>
                    <p className="fl-subtitle">
                        Flexible, scalable architecture with optimized resource distribution
                    </p>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            <p className="fl-title mb-3">
                                Seamless Hybrid Mesh Orchestration for Scalable and Flexible Connectivity
                            </p>
                            <p className="fl-text">
                                AVAP Mesh Manager simplifies hybrid architecture management, offering a unified interface for optimizing resource distribution, scaling, and load balancing. It allows seamless adaptation of on-premise and logical configurations, ensuring maximum flexibility while enhancing scalability and performance without the need for physical resource movement.
                            </p>
                        </div>
                    </div>

                </section>

                {/* Features */}
                {/*       <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Unified Management</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Real-Time Optimization</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Built-In Security</p>
                            </div>
                        </div>
                    </div>
                </section> */}

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                /*   className="img-fluid" */
                                height="500px"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">KEY FEATURES</span>
                            <h3 className="fl-title">Centralized Multi-Environment Management</h3>
                            <p className="fl-text">
                                Manage all AVAP Mesh data planes from a single interface, ensuring seamless operations across hybrid, multi-cloud, and on-premise environments. This centralized control simplifies oversight, enhances consistency, and boosts operational efficiency across diverse infrastructures.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">KEY FEATURES</span>
                            <h3 className="fl-title">Automated Scaling & Optimization</h3>
                            <p className="fl-text">
                                Dynamically adjust API traffic, security policies, and performance settings in real time, ensuring your network is always optimized for high availability and peak efficiency. This feature adapts to traffic spikes, minimizes latency, and ensures resources are used effectively, leading to enhanced performance and reduced operational overhead.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">KEY FEATURES</span>
                            <h3 className="fl-title">Virtual Layer Flexibility</h3>
                            <p className="fl-text">
                                Leverage a virtual layer that enables custom configuration without physical resource changes. This flexible architecture allows you to easily scale and adapt your infrastructure to meet evolving demands without the need for manual intervention or hardware adjustments.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col mb-5">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Flexibility
                                </p>
                                <p className="fl-text">
                                    Easily configure your hybrid architecture with a virtual layer, allowing seamless adjustments to resources and settings without physical changes to infrastructure.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhances Security & Compliance
                                </p>
                                <p className="fl-text">
                                    Maintain a robust security posture with built-in encryption, authentication, and traffic control, ensuring reliable protection for sensitive data.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Boosts Performance & Scalability
                                </p>
                                <p className="fl-text">
                                    Enable seamless application growth with intelligent traffic routing, load balancing, and adaptive scaling, ensuring APIs run efficiently at any scale.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
