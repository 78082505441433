import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';



import fondo from '../../assets/img/landingPage/fondoApps.png';


import tablet_img from '../../assets/img/landingPage/extensions/Head_Captura_Extensiones.png'

import features_1 from '../../assets/img/landingPage/extensions/APILifecycle_0.5.png'
import features_2 from '../../assets/img/landingPage/extensions/SeamlessIntegration_0.5.png'
import features_3 from '../../assets/img/landingPage/extensions/DevStudioMVSC-03.png'

import extension_one from '../../assets/img/landingPage/extensions/Extensions_01.png'
import extension_two from '../../assets/img/landingPage/extensions/Extensions_02.png'
import extension_three from '../../assets/img/landingPage/extensions/Extensions_03.png'
import extension_four from '../../assets/img/landingPage/extensions/Extensions_04.png'
import extension_five from '../../assets/img/landingPage/extensions/Extensions_05.png'


import logo from '../../assets/img/logos/extensions_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import avap_img from '../../assets/img/landingPage/extension-avap@2x.png'
import devstudio_img from '../../assets/img/landingPage/extension-dev@2x.png'
import publisher_img from '../../assets/img/landingPage/extension-publisher@2x.png'
import specs_img from '../../assets/img/landingPage/extension-specs@2x.png'
import version_img from '../../assets/img/landingPage/extension-version@2x.png'







const AvapProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">/* THE ALGORITHM THAT PROVES 1+1 IS NOT 2 */</h1>
                    <p className="fl-subtitle">
                        Empowering developers to use AVAP within their <br /> preferred development environment
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>


                </section>

                {/* Powerful API Platform */}
                <section className="text-center pb-5 container">
                    <div className="row">
                        <div className="col">


                            <img
                                src={tablet_img}
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-4"
                            />

                            <h3 className="fl-subtitle-xs">Unlock Seamless API Development</h3>
                            <p className="fl-text">
                                AVAP Dev Studio Extensions are powerful tools designed to seamlessly integrate AVAP programming
                                language into Microsoft Visual Code. By leveraging AVAP extensions, developers can efficiently build, test,
                                and manage Virtual APIs within their familiar coding workspace while taking full advantage of the language’s
                                specialized features tailored for API development.
                            </p>
                        </div>
                    </div>
                </section>


                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>

                <section className="container mb-5">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Extensions</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="fl-card grayGradient">
                                <img
                                    src={extension_one}
                                    alt="Platform Placeholder"
                                    className="img-fluid "
                                />

                            </div>
                        </div>
                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <img className='labelImg' src={devstudio_img} alt="dev Studio" />
                                    {/* <div className="fl-subtitle">
                                        AVAP Dev Extension
                                    </div> */}
                                </div>
                                <div className="fl-text">
                                    Core tool for managing the full API lifecycle. For a complete development, management, and releasing experience, additional extensions are required: AVAP Source Control for  for version tracking and AVAP Release Manager for releasing APIs across environments.
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <img className='labelImg' src={version_img} alt="version control" />
                                </div>
                                <div className="fl-text">
                                    This extension allows you to create code repositories, manage code evolution, and track deployed versions across environments for efficient version control and seamless development.
                                </div>

                            </div>
                        </div>

                        <div className="col-4">
                            <div className="fl-card grayGradient">
                                <img src={extension_two} alt="extension two" className="img-fluid " />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="fl-card grayGradient">
                                <img src={extension_three} alt="extension three" className="img-fluid " />
                            </div>
                        </div>
                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <img className='labelImg' src={publisher_img} alt="Publisher" />
                                </div>
                                <div className="fl-text">
                                    It integrates seamlessly with other extensions in the AVAP Dev family, enabling the releasing of APIs developed in the AVAP language. It also supports the releasing of APIs created in other technologies with an endpoint. AVAP Dev Extension for Visual Studio Code is required.

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <img className='labelImg' src={avap_img} alt="Avap" />
                                    <div className="fl-subtitle">
                                        AVAP Linter & Completion
                                    </div>
                                </div>
                                <div className="fl-text">
                                    A Visual Studio Code extension with rich
                                    support for AVAP language, providing access points for extensions to seamlessly integrate and provide a
                                    complete API lifecycle management, formatting, linting, code navigation, and more.
                                </div>

                            </div>
                        </div>

                        <div className="col-4">
                            <div className="fl-card grayGradient">
                                <img src={extension_four} alt="extension four" className="img-fluid " />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="fl-card grayGradient">
                                <img src={extension_five} alt="extension five" className="img-fluid " />

                            </div>
                        </div>
                        <div className="col-8">
                            <div className='fl-card grayGradient'>
                                <div className="fl-wrapper-label">
                                    <img className='labelImg' src={specs_img} alt="Specs" />

                                </div>
                                <div className="fl-text">
                                    This extension simplifies publishing and managing API specifications.
                                    In collaboration with the Open API Initiative, this extension allows developers to effortlessly work with the OpenAPI Specification (OAS),
                                    streamlining API documentation within their IDE.
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">API Development Streamlined</h3>
                            <p className="fl-text">
                                Enhance API design, building, development, and testing with AVAP extensions in Visual Studio Code, enabling efficient API creation and testing workflows.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Seamless Integration with AVAP</h3>
                            <p className="fl-text">
                                Effortlessly incorporate AVAP features into your coding workflow.
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>

                    </div>
                </section>


                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-8">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Release Management</h3>
                            <p className="fl-text">
                                Manage multiple API releases and present them across environments with ease.
                            </p>
                        </div>
                    </div>
                </section>


                <div className="bottomfl-wrapper-label position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom opacity-5" />

                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default AvapProduct;
