/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RouteProps, Route, Redirect } from 'react-router-dom';
import LoginForm from '../components/loginForm/LoginForm';
import LoginActivationForm from '../components/loginForm/LoginActivationForm';
import GetAuthorizationForm from '../components/loginForm/getAuthorization';
import React from 'react';
import SessionService from '../services/SessionsService';
import ContentDoc from '../components/ContentDoc/ContentDoc';
import DashboardContent from '../components/DashboardComponents/Dashboard';
import SingupForm from '../components/Signup/signup';
import PlansResume from '../components/PlansAndPricing/PlansResume';
import PageBlank from '../components/PageBlank/PageBlank';
import AccountVerified from '../components/AccountVerified/AccountVerified';
import VerifySignUp from '../components/Signup/VerifySignup';
import ProjectsContent from '../components/DashboardComponents/Projects';
import StagingProjects from '../components/DashboardComponents/stagingProjects';
import ProjectInfo from '../components/DashboardComponents/ProjectInfo';
import ProjectTransactionsInfo from '../components/DashboardComponents/ProjectTransactionsInfo';
// import apisRouter from './routers/apis-router.json';
import apisRouter from './routers/new-apis-router'
// import apiRouterwiki from './routers/apis-routerwiki.json';
import apiRouterwiki from './routers/new-apis-routerWiki'
import downloadsRouter from './routers/downloads-router.json';
// import docsRouter from './routers/docs-router.json'; 
import docsRouter from './routers/new-docs-router';
import faqsRouter from './routers/faqs-routers.json';
import DashRouters from './routers/dashboard-routers.json';
import DevDashRouters from './routers/dev-dashboard-routers.json';
import ClientDashRouters from './routers/client-dashboard-routers.json';
import TransactionsContent from '../components/DashboardComponents/Transactions';
import ExchangeContent from '../components/DashboardComponents/Exchange';
import IncomeContent from '../components/DashboardComponents/Income';
import AccountInformationContent from '../components/DashboardComponents/SettingComponents/AccountInformation';
import TaxDetailsContent from '../components/DashboardComponents/SettingComponents/TaxInformation';
import PaymentMethodContent from '../components/DashboardComponents/SettingComponents/PaymentMethods';
import PublicPage from '../components/publicPage/PublicPage';
import ChangePassword from '../components/changeRecovery/changePassword';
import BalanceContent from '../components/DashboardComponents/BalanceComponents/BalanceContent';
import PaymentsContent from '../components/DashboardComponents/PaymentsComponents/PaymentsContent';
import CookiesManage from '../components/cookiesManage/cookiesManage';
import ChangePasswordSetting from '../components/DashboardComponents/SettingComponents/changePasswordSetting';
import ChangePlanDurationContent from '../components/PlanSubcriptionComponents/changePlanDuration';
import PlanSubscriptionContent from '../components/PlanSubcriptionComponents/PlanSubcription';
import PayoutsContent from '../components/DashboardComponents/SettingComponents/payouts';
import LogsContent from '../components/Developers/Logs';
import Sandbox from '../components/Developers/Sandbox';
import ApiKeysContent from '../components/Developers/apiKeys';
import CreateSubcriptionContent from '../components/DashboardComponents/AdminComponents/createSubcription';
import CommissionContent from '../components/DashboardComponents/Commissions';
import ProjectCurrencies from '../components/DashboardComponents/Currencies';
import ProjectCurrenciesActivated from '../components/DashboardComponents/CurrenciesActivated';
import MarketPlaceProjects from '../components/marketPlaceComponents/MarketPlaceProjects';
import MarketPlaceContent from '../components/marketPlaceComponents/marketPlaceContent';
import BankAccountContent from '../components/DashboardComponents/SettingComponents/BankAccount';
import UpdatePlan from '../components/PlansAndPricing/UpdatePlan';
import DeveloperContent from '../components/DashboardComponents/Developer';
import CustomSignUp from '../components/Signup/customsignup';
import OrganizationTeams from '../components/DashboardComponents/DeveloperTeams';
import Organizations from '../components/DashboardComponents/Organizations';
import Publisher from '../components/DashboardComponents/Publisher';
import DevDashboardContent from '../components/DashboardComponents/DevDashboard';
import MyTeams from '../components/DashboardComponents/MyTeams';
import DevTransactionsContent from '../components/DashboardComponents/DevTransactions';
import DevOrganizations from '../components/DashboardComponents/DevOrganizations';
import DeveloperPersonalContent from '../components/DashboardComponents/MyDevData';
import DeveloperDownloadContent from '../components/DashboardComponents/MyDevDownload';
import DownloadDownloadContent from '../components/DashboardComponents/MyDownDownload';
import RepoDownloadContent from '../components/DashboardComponents/MyRepoDownload';
import DevProjectsContent from '../components/DashboardComponents/DevProjects';
import DevLogsContent from '../components/Developers/DevLogs';
import DevSandbox from '../components/Developers/DevSandbox';
import DevStagingProjects from '../components/DashboardComponents/DevstagingProjects';
import LoginOpenBankingForm from '../components/loginForm/LoginOpenBanking';
import Privileges from '../components/DashboardComponents/Privileges';
import AcceptInvitation from '../components/loginForm/AcceptInvitation';
import AcceptProduction from '../components/loginForm/Acceptproduction';
import ConfirmClient from '../components/loginForm/ConfirmClient';
import ClientDashboardContent from '../components/DashboardComponents/ClientDashboard';
import ClientProjectsContent from '../components/DashboardComponents/ClienttProjects';
import ClientProjectInfo from '../components/DashboardComponents/ClientProjectInfo';
import AcceptChangeProduction from '../components/loginForm/AcceptChangeproduction';
import ProjectDevInfo from '../components/DashboardComponents/ProjectDevInfo copy';
import DevAccountInformationContent from '../components/DashboardComponents/SettingComponents/DevAccountInformation';
import ClientAccountInformationContent from '../components/DashboardComponents/SettingComponents/ClientAccountInformation';
import ProjectDevInfoStaging from '../components/DashboardComponents/ProjectDevInfoStaging';
import ApiTestContent from '../components/Developers/apiTest';
import ApiTestResult from '../components/Developers/apiResult';
import ApiStatus from '../components/Developers/apiStatus';
import ContentWiki from '../components/wiki/wikiMenu';
import NewDashboard from '../newViews/newDashboard/NewDashboard';
import Register from '../newViews/newDashboard/NewRegisterPage/NewRegisterpage';
import Avapcloud from '../newViews/newAVAPCLOUD/avapcloud'
import Avapcloud_en from '../newViews/newAVAPCLOUD/avapcloud_en'
import Avapidverify from '../newViews/newDashboard/verifyAvapid/AvapidVerify';
import DashboardAVAPCLOUD from '../newViews/newAVAPCLOUD/dashboardAVAPCLOUD/dashboardAVAPCLOUD';
import CloudLoginForm from '../components/loginForm/CloudLoginForm';
import VerifyCloudSignUp from '../components/Signup/VerifyCloudSignup';
import VerifyCloudSignUp_en from '../components/Signup/VerifyCloudSignup_en';
import NewLanding from '../newViews/landing/NewLanding';
import NewLanding2 from '../newViews/landing_framework/NewLandingFramework';
import SwaggerView from '../newViews/landing/swaggerview';
import NewLandingCollaborative from '../newViews/landing_framework/NewLandingCollaborative';
import NewLandingCloud from '../newViews/landing_framework/NewLandingCloud';
import NewLandingDevStudio from '../newViews/landing_framework/NewLandingDevStudio';
import NewLandingAVS from '../newViews/landing_framework/NewLandingAVS';
import NewLandingGateway from '../newViews/landing_framework/NewLandingGateway';
import NewLandingAI from '../newViews/landing_framework/NewLandingAI';
import NewLandingAVAP from '../newViews/landing_framework/NewLandingAVAP';
import NewLandingABOUT from '../newViews/landing_framework/NewLandingAboutUS';
import PublisherContent from '../components/DashboardComponents/Publisher';
import NewLandingVersionControl from '../newViews/landing_framework/NewLandingVersionControl';
import NewLandingAPIPublisher from '../newViews/landing_framework/NewLandingAPIPublisher';
import NewLandingExtensions from '../newViews/landing_framework/NewLandingExtensions';
import NewLandingMarketplace from '../newViews/landing_framework/NewLandingMarketplace';
import NewLandingConnectors from '../newViews/landing_framework/NewLandingConnectors';
import NewLandingAVAPID from '../newViews/landing_framework/NewLandingAVAPID';


//new landing page
import Avap from '../newViews/landing_framework/Avap';
import Cloud from '../newViews/landing_framework/Cloud';
import Brunix from '../newViews/landing_framework/Brunix'
import PublisherNW from '../newViews/landing_framework/Publisher';
import VersionControl from '../newViews/landing_framework/VersionControl';
import Connectors from '../newViews/landing_framework/Connectors';
import Collab from '../newViews/landing_framework/Collab';
import Market from '../newViews/landing_framework/Market';
import Gateway from '../newViews/landing_framework/Gateway';
import Platon from '../newViews/landing_framework/Platon';
import Ide from '../newViews/landing_framework/Ide';
import Avs from '../newViews/landing_framework/VirtualServer';
import Extensions from '../newViews/landing_framework/Extensions';
import Interpreter from '../newViews/landing_framework/interpreter';
import AboutUs from '../newViews/landing_framework/Company';
import Roadmap from '../newViews/landing_framework/Roadmap';
import Pricing from '../newViews/landing_framework/Pricing';
import Contact from '../newViews/landing_framework/contacto';
import MeshManager from '../newViews/landing_framework/MeshManager';
import Commander from '../newViews/landing_framework/Commander';
import ReleaseManager from '../newViews/landing_framework/ReleaseManager';
import Vision from '../newViews/landing_framework/AVAPVision';
import Demo from '../newViews/landing_framework/demo';
import Connect from '../newViews/landing_framework/Connect';
import Cli from '../newViews/landing_framework/Cli';
import Shell from '../newViews/landing_framework/Shell';

///new imports for cloud views
import CloudCatalog from '../components/DashboardComponents/apiCatalog';
import CloudRoles from '../components/DashboardComponents/Roles';
import CloudPermits from '../components/DashboardComponents/Permits';

import CloudTokens from '../components/DashboardComponents/tokens';
import CloudCerts from '../components/DashboardComponents/certs';
import Cloudkeys from '../components/DashboardComponents/keys'
import CloudProxy from '../components/DashboardComponents/proxies'
import CloudAmbients from '../components/DashboardComponents/ambients'
import CloudApiproduct from '../components/DashboardComponents/ApiProduct'
import CloudApiconfig from '../components/DashboardComponents/Apiconfig'
import CloudApiproxy from '../components/DashboardComponents/ApiProxy'
import CloudGateManager from '../components/DashboardComponents/gatewayManager'

//DevPortal
import DevPortal_login from '../newViews/DevPortal/login'
import DevPortal_front from '../newViews/DevPortal/DevPortal'
import DevPortal_front_alt from '../newViews/DevPortal/DevPortal_alt'
import DevPortal_catalog from '../newViews/DevPortal/AppCatalog'
import PublicDevPortal_catalog from '../newViews/DevPortal/AppPublicCatalog'
import DevPortal_catalog_alt from '../newViews/DevPortal/AppCatalog_alt'
import DevPortal_documentation from '../newViews/DevPortal/documentation'
import DevPortal_documentation_by_app from '../newViews/DevPortal/documentation_by_app'
import DevPortal_documentation_alt from '../newViews/DevPortal/documentation_alt'
import DynamicRouteWrappers from '../newViews/DevPortal/DynamicRouteWrapper';

///test nuevo dashboard Avap Cloud Black

import AvapCloudDashboard from '../newViews/avapCloud/Dashboard';
import AvapCloudCompanies from '../newViews/avapCloud/organizations';
import AvapCloudTeams from '../newViews/avapCloud/teams';
import AvapCloudDevs from '../newViews/avapCloud/Devs';
import AvapCloudOrgPay from '../newViews/avapCloud/payOrganization';
import AvapApiProducts from '../newViews/avapCloud/ApiProducts';
import Components from '../newViews/avapCloud/components';

import DevPortalManager from '../components/DashboardComponents/developer_portals';
import DevPortalUsers from '../components/DashboardComponents/DevPortalUsers';
import DevPortalApps from '../components/DashboardComponents/DevPortalApps';
import DevPortalDocs from '../components/DashboardComponents/DevPortalAppsDocs';
import DevPortalIndex from '../components/DashboardComponents/DevPortalAppsIndex';


const Private = () => { return <h1>PRIVADO</h1> };
const Public = () => { return <h1>PUBLICO</h1> };
const isAdmin = (): boolean => SessionService.isAdmin();
const isDev = (): boolean => SessionService.isDev();
const isAVAPID = (): boolean => SessionService.isAVAPID();
/**
 * Este bloque crea un componente que usaremos para rutas privadas
 * es decir, todas aquellas que se necesite estar logueado previamente
 * el funcionamiente lo que hace es comprobar si está loguead manda a la ruta, 
 * en caso de que no redirecciona al login.
 */
export const PrivateRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        1 > 2 ? ( // Comprobacion chorra(TOTAL NADIE USA ESTO)
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};


export const RootRoute: React.ComponentType<any> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAdmin() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

// Rutas a las que todo el mundo tiene acceso
export const publicRoutes: Array<RouteProps> = [
  {
    path: '/',
    component: () =>
      window.location.hostname == '101obex.com' || window.location.hostname == '101obex.com' ? <Redirect to="/about" /> :
        window.location.hostname == '0.0.0.0' || window.location.hostname == 'avapid.com' || window.location.hostname == 'staging.avapid.com' || window.location.hostname == 'www.avapid.com' ?
          <Redirect to="/login" /> :
          (window.location.hostname == 'staging.avapcloud.com' || window.location.hostname == 'avapcloud.com' || window.location.hostname == 'www.avapcloud.com' ?
            <Redirect to="/cloud_login" />
            :
            (window.location.hostname == 'developer.avapframework.com' ?
              <Redirect to="/developer" />
              :
              <Redirect to="/framework" />
            )
          )

    ,
    exact: true
  },
  {
    path: '/cloudBlack',
    component: () => <AvapCloudDashboard />,
    exact: true
  },
  {
    path: '/cloudBlack/organizations',
    component: () => <AvapCloudCompanies />,
    exact: true
  },
  {
    path: '/cloudBlack/organizations/payment',
    component: () => <AvapCloudOrgPay />,
    exact: true
  },
  {
    path: '/cloudBlack/organizations/teams',
    component: () => <AvapCloudTeams />,
    exact: true
  },
  {
    path: '/cloudBlack/organizations/devs',
    component: () => <AvapCloudDevs />,
    exact: true
  },
  {
    path: '/cloudBlack/api/products',
    component: () => <AvapApiProducts />,
    exact: true
  }, {
    path: '/cloudBlack/components',
    component: () => <Components />,
    exact: true
  },
  {
    path: '/framework',
    component: () => <NewLanding2 />,
    exact: true
  },
  {
    path: '/collaborative',
    component: () => <Collab />,
    exact: true
  },
  {
    path: '/cli',
    component: () => <Cli />,
    exact: true
  },
  {
    path: '/shell',
    component: () => <Shell />,
    exact: true
  },
  {
    path: '/cloud',
    component: () => <Cloud />,
    exact: true
  },

  {
    path: '/demo',
    component: () => <Demo />,
    exact: true
  },
  {
    path: '/avs',
    component: () => <Avs />,
    exact: true
  },
  {
    path: '/brunix',
    component: () => <Brunix />,
    exact: true
  },
  {
    path: '/platon',
    component: () => <Platon />,
    exact: true
  },
  {
    path: '/interpreter',
    component: () => <Interpreter />,
    exact: true
  },
  {
    path: '/roadmap',
    component: () => <Roadmap />,
    exact: true
  },
  {
    path: '/pricing',
    component: () => <Pricing />,
    exact: true
  },
  {
    path: '/contact',
    component: () => <Contact />,
    exact: true
  },
  {
    path: '/devstudio',
    component: () => <Ide />,
    exact: true
  },
  {
    path: '/gateway',
    component: () => <Gateway />,
    exact: true
  },
  {
    path: '/meshmanager',
    component: () => <MeshManager />,
    exact: true
  },
  {
    path: '/commander',
    component: () => <Commander />,
    exact: true
  },
  {
    path: '/ai',
    component: () => <NewLandingAI />,
    exact: true
  },
  {
    path: '/avap',
    component: () => <Avap />,
    exact: true
  },
  {
    path: '/Register',
    component: () => <Register />,
    exact: true
  },
  {
    path: '/downloads',
    component: () => <ContentDoc sider={downloadsRouter} doc="download_github" cat="download" />,
    exact: true
  },
  {
    path: '/downloads/:cat/:name',
    component: props => <ContentDoc
      sider={downloadsRouter}
      doc={props.match.params.name}
      cat={props.match.params.cat}
    />,
    exact: true
  },
  {
    path: '/apis',
    component: () => <ContentDoc sider={apisRouter} doc="Accesos_EN" cat="login" />,
    exact: true
  },
  {
    path: '/apis/:cat/:name/:section',
    component: props => <ContentDoc
      sider={apisRouter}
      doc={props.match.params.name}
      cat={props.match.params.cat}
      section={props.match.params.section}
    />,
    exact: true
  },
  {
    path: '/wiki',
    component: () => <ContentWiki sider={apiRouterwiki} doc="Currencies_FX_Exchange_EN" cat="login" />,
    exact: true
  },
  {
    path: '/wiki/:cat/:name',
    component: props => <ContentWiki
      sider={apiRouterwiki}
      doc={props.match.params.name}
      cat={props.match.params.cat}
    />,
    exact: true
  },
  {
    path: '/docs',
    component: () => <ContentDoc sider={docsRouter} doc="Currencies_FX_Exchange_EN" cat="login" />,
    exact: true
  },
  {
    path: '/default',
    component: () => <ContentDoc sider={apiRouterwiki} doc="Currencies_FX_Exchange_EN" cat="default" />,
    exact: true
  },
  {
    path: '/developer',
    component: () => <ContentDoc sider={apiRouterwiki} doc="Currencies_FX_Exchange_EN" cat="developer" />,
    exact: true
  },
  ,
  {
    path: '/docs/:cat/:name',
    component: props => <ContentDoc
      sider={docsRouter}
      doc={props.match.params.name}
      cat={props.match.params.cat}
    />,
    exact: true
  },
  {
    path: '/faq',
    component: () => <ContentDoc sider={faqsRouter} doc="Currencies_FX_Exchange_EN" cat="login" />,
    exact: true
  },
  {
    path: '/faq/:cat/:name',
    component: props => <ContentDoc
      sider={faqsRouter}
      doc={props.match.params.name}
      cat={props.match.params.cat}
    />,
    exact: true
  },
  //devportal routes
  {
    path: '/devportal/:id/login',
    component: () => <DevPortal_login />,
    exact: true
  },
  {
    path: '/devportal/:id',
    render: ({ match }) => <Redirect to={`/devportal/${match.params.id}/login`} />,
    exact: true,
  },
  {
    path: '/devportal/:id/portal',
    exact: true,
    render: (props) => (
      <DynamicRouteWrappers
        {...props}
        defaultComponent={DevPortal_front}
        alternativeComponent={DevPortal_front_alt}
      />
    )
  },
  {
    path: '/devportal/:id/catalog',
    exact: true,
    render: (props) => (
      <DynamicRouteWrappers
        {...props}
        defaultComponent={DevPortal_catalog}
        alternativeComponent={DevPortal_catalog_alt}
      />
    )
  },
  {
    path: '/devportal/:id/public_catalog',
    exact: true,
    render: (props) => (
      <DynamicRouteWrappers
        {...props}
        defaultComponent={PublicDevPortal_catalog}
        alternativeComponent={DevPortal_catalog_alt}
      />
    )
  },
  {
    path: '/devportal/:id/documentation',
    exact: true,
    render: (props) => (
      <DynamicRouteWrappers
        {...props}
        defaultComponent={DevPortal_documentation}
        alternativeComponent={DevPortal_documentation_alt}
      />
    )
  },
  {
    path: '/devportal/:id/documentation_by_app/:app_id',
    exact: true,
    render: (props) => (
      <DynamicRouteWrappers
        {...props}
        defaultComponent={DevPortal_documentation_by_app}
        alternativeComponent={DevPortal_documentation_alt}
      />
    )
  },
  //
  {
    path: '/docs/in_process',
    component: () => <ContentDoc sider={docsRouter} doc="Ciphered_Nodes_EN" cat="login" />,
    exact: true
  },
  {
    path: '/cloud_dashboard',
    component: () => (!isDev ? <CloudLoginForm /> : <DashboardContent sider={DashRouters} />),
    // exact: true
  },
  {
    path: '/dashboard',
    component: () => (!isAVAPID ? <LoginForm /> : <NewDashboard />),
    // exact: true
  },
  //!! IM EDIT THIS
  {
    path: '/devdashboard',
    component: () => (!isDev ? <LoginForm /> : <DevDashboardContent sider={DevDashRouters} />),
    exact: true
  },
  ,
  {
    path: '/clientdashboard',
    component: () => (!isDev ? <LoginForm /> : <ClientDashboardContent sider={ClientDashRouters} />),
    exact: true
  },
  /*
  {
    path: '/login',
    component: () => <LoginForm />,
    exact: true
  },
  {
    path: '/cloud_login',
    component: () => <CloudLoginForm />,
    exact: true
  },
*/
  {
    path: '/openbanking/:Token',
    component: props => <LoginOpenBankingForm Token={props.match.params.Token} />,
    exact: true
  },

  {
    path: '/signup/1',
    component: () => {
      return (<SingupForm
        extraProjectsPlan={0}
        extraRequestsPlan={0}
        planID={1}
        totalPriceMonthly={0}
        totalPriceYear={0}
        nameProject={'Developer'}
        pricePlanMonthly={0}
        pricePlanYear={0}
        txPriceMonthly={null}
        txPriceYear={null}
        projectPriceMonthly={null}
        projectPriceYear={null}
        projectDesc={null}
        txDesc={null}
      />)
    },
    exact: true
  },
  {
    path: '/signup/2',
    component: () => {
      return (<SingupForm
        extraProjectsPlan={0}
        extraRequestsPlan={0}
        planID={2}
        totalPriceMonthly={50}
        totalPriceYear={50}
        nameProject={'Startup'}
        pricePlanMonthly={50}
        pricePlanYear={50}
        txPriceMonthly={null}
        txPriceYear={null}
        projectPriceMonthly={null}
        projectPriceYear={null}
        projectDesc={null}
        txDesc={null}
      />)
    },
    exact: true
  },
  {
    path: '/signup/3',
    component: () => {
      return (<SingupForm
        extraProjectsPlan={0}
        extraRequestsPlan={0}
        planID={3}
        totalPriceMonthly={150}
        totalPriceYear={150}
        nameProject={'Business'}
        pricePlanMonthly={150}
        pricePlanYear={150}
        txPriceMonthly={null}
        txPriceYear={null}
        projectPriceMonthly={null}
        projectPriceYear={null}
        projectDesc={null}
        txDesc={null}
      />)
    },
    exact: true
  },
  {
    path: '/signup',
    component: (state) => {
      return (<SingupForm
        extraProjectsPlan={state.location.state.extraProjectsPlan}
        extraRequestsPlan={state.location.state.extraRequestsPlan}
        planID={state.location.state.planID}
        totalPriceMonthly={state.location.state.totalPriceMonthly}
        totalPriceYear={state.location.state.totalPriceYear}
        nameProject={state.location.state.nameProject}
        pricePlanMonthly={state.location.state.pricePlanMonthly}
        pricePlanYear={state.location.state.pricePlanYear}
        txPriceMonthly={state.location.state.txPriceMonthly}
        txPriceYear={state.location.state.txPriceYear}
        projectPriceMonthly={state.location.state.projectPriceMonthly}
        projectPriceYear={state.location.state.projectPriceYear}
        projectDesc={state.location.state.projectDesc}
        txDesc={state.location.state.txDesc}
      />)
    },
    exact: true
  },
  {
    path: '/plans',
    component: () => <PlansResume />,
    exact: true
  },
  {
    path: '/plans/:planID',
    component: props => <PlansResume planID={props.match.params.planID} />,
    exact: true
  },
  {
    path: '/customsignup/:Token',
    component: props => <CustomSignUp Token={props.match.params.Token} />,
    exact: true
  },
  {
    path: '/get_authorization/:Token',
    component: props => <GetAuthorizationForm Token={props.match.params.Token} />,
    exact: true
  },
  {
    path: '/accept_invitation/:Token',
    component: props => <AcceptInvitation Token={props.match.params.Token} />,
    exact: true
  },
  {
    path: '/accept_production/:Token',
    component: props => <AcceptProduction Token={props.match.params.Token} />,
    exact: true
  },
  {
    path: '/accept_change_production/:Token',
    component: props => <AcceptChangeProduction Token={props.match.params.Token} />,
    exact: true
  },
  {
    path: '/confirm_client/:Token',
    component: props => <ConfirmClient Token={props.match.params.Token} />,
    exact: true
  },
  {
    path: '/updateplans/:planID',
    component: props => <UpdatePlan planID={props.match.params.planID} />,
    exact: true
  },
  {
    path: '/plan_subcription/upgrade_plan',
    component: () => <UpdatePlan />,
    exact: true
  },
  {
    path: '/blank',
    component: () => <PageBlank />,
    exact: true
  },
  {
    path: '/verify_account/:key',
    component: () => <AccountVerified />,
    exact: true
  },
  {
    path: '/verify_avapid/:key',
    component: () => <Avapidverify />,
    exact: true
  },
  {
    path: '/verify_signup',
    component: () => <VerifySignUp />,
    exact: true
  },
  {
    path: '/verify_cloud_signup/:token',
    component: () => <VerifyCloudSignUp />,
    exact: true
  },


  {
    path: '/business-success',
    component: () => <VerifyCloudSignUp />,
    exact: true
  },
  {
    path: '/professional-success',
    component: () => <VerifyCloudSignUp />,
    exact: true
  },

  {
    path: '/business-success_en',
    component: () => <VerifyCloudSignUp_en />,
    exact: true
  },
  {
    path: '/professional-success_en',
    component: () => <VerifyCloudSignUp_en />,
    exact: true
  },


  {
    path: '/projects',
    component: () => <ProjectsContent />,
    exact: true
  },
  {
    path: '/dev_projects',
    component: () => <DevProjectsContent />,
    exact: true
  },
  {
    path: '/client_projects',
    component: () => <ClientProjectsContent />,
    exact: true
  },
  {
    path: '/client_projects/:project',
    component: (state) => <ClientProjectInfo
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  {
    path: '/projects/:project',
    component: (state) => <ProjectInfo
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  {
    path: '/dev_projects/:project',
    component: (state) => <ProjectDevInfo
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  {
    path: '/dev_sta_projects/:project',
    component: (state) => <ProjectDevInfoStaging
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },
  {
    path: '/projects/:project/commissions',
    component: () => <CommissionContent />,
    exact: true
  },
  {
    path: '/transactions',
    component: () => <TransactionsContent />,
    exact: true
  },
  {
    path: '/transactions/:project',
    component: (state) => <ProjectTransactionsInfo
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },

  {
    path: '/dev_transactions',
    component: () => <DevTransactionsContent />,
    exact: true
  },
  {
    path: '/dev_transactions/:project',
    component: (state) => <ProjectTransactionsInfo
      projects={getProjectsFromState(state)}
    />,
    exact: true
  },

  /*
   
    {
      path: '/openbanking/:Token',
      component: props => <LoginOpenBankingForm Token={props.match.params.Token}/>,
      exact:true
    },
   
  */

  {
    path: '/privileges/:developer_id',
    component: props => <Privileges developer_id={props.match.params.developer_id} />,
    exact: true
  },

  {
    path: '/exchange',
    component: () => <ExchangeContent />,
    exact: true
  },
  {
    path: '/publisher',
    component: () => <Publisher />,
    exact: true
  },
  {
    path: '/avap-vision',
    component: () => <Vision />,
    exact: true
  },
  {
    path: '/exchange/:project',
    component: () => <ProjectCurrencies />,
    exact: true
  },
  {
    path: '/projects/:project/exchange_activated/',
    component: () => <ProjectCurrenciesActivated />,
    exact: true
  },
  {
    path: '/income',
    component: () => <IncomeContent />,
    exact: true
  },
  {
    path: '/source-control',
    component: () => <VersionControl />,
    exact: true
  },
  {
    path: '/vsc-extensions',
    component: () => <Extensions />,
    exact: true
  },
  {
    path: '/avap-marketplace',
    component: () => <Market />,
    exact: true
  },
  {
    path: '/api-publisher',
    component: () => <PublisherNW />,
    exact: true
  },
  {
    path: '/avapid',
    component: () => <NewLandingAVAPID />,
    exact: true
  },
  {
    path: '/avap-connectors',
    component: () => <Connectors />,
    exact: true
  },
  {
    path: '/connect',
    component: () => <Connect />,
    exact: true
  },
  {
    path: '/settings/account_information',
    component: () => <AccountInformationContent />,
    exact: true
  },

  {
    path: '/settings/tax_details',
    component: () => <TaxDetailsContent />,
    exact: true
  },
  {
    path: '/settings/payment_methods',
    component: () => <PaymentMethodContent />,
    exact: true
  },
  {
    path: '/settings/change_password',
    component: () => <ChangePasswordSetting />
  },
  {
    path: '/income/payouts',
    component: () => <PayoutsContent />
  },
  {
    path: '/settings/bankaccount',
    component: () => <BankAccountContent />
  },
  {
    path: '/settings',
    component: () => <AccountInformationContent />,
  },
  {
    path: '/dev_settings',
    component: () => <DevAccountInformationContent />,
  },
  {
    path: '/client_settings',
    component: () => <ClientAccountInformationContent />,
  },
  {
    path: '/public',
    component: () => <PublicPage />
  },
  {
    path: '/change_password/:token',
    component: () => <ChangePassword />
  },
  {
    path: '/avap_cloud/dashboard',
    component: () => <DashboardAVAPCLOUD />
  },
  {
    path: '/avap_cloud/:token',
    component: () => <Avapcloud />
  },
  {
    path: '/avap_cloud',
    component: () => <Avapcloud />
  },
  {
    path: '/about',
    component: () => <AboutUs />
  },
  
  {
    path: '/resend_activation/:token',
    component: () => <LoginActivationForm />
  },
  {
    path: '/developers',
    component: () => <DeveloperContent />,
    exact: true
  },
  {
    path: '/developers_personal',
    component: () => <DeveloperPersonalContent />,
    exact: true
  },

  {
    path: '/teams',
    component: () => <OrganizationTeams />,
    exact: true
  },
  ////New cloud views
  {
    path: '/roles',
    component: () => <CloudRoles />,
    exact: true
  },
  {
    path: '/catalog',
    component: () => <CloudCatalog />,
    exact: true
  },
  {
    path: '/permits',
    component: () => <CloudPermits />,
    exact: true
  },
  {
    path: '/tokens',
    component: () => <CloudTokens />,
    exact: true
  },
  {
    path: '/certs',
    component: () => <CloudCerts />,
    exact: true
  },
  {
    path: '/keys',
    component: () => <Cloudkeys />,
    exact: true
  },
  {
    path: '/proxies',
    component: () => <CloudProxy />,
    exact: true
  },
  {
    path: '/dev_portals',
    component: () => <DevPortalManager />,
    exact: true
  },
  {
    path: '/dev_portals_users',
    component: () => <DevPortalUsers />,
    exact: true
  },
  {
    path: '/dev_portals_apps',
    component: () => <DevPortalApps />,
    exact: true
  },
  ,
  {
    path: '/dev_portals_docs',
    component: () => <DevPortalDocs />,
    exact: true
  },
  {
    path: '/dev_portals_index',
    component: () => <DevPortalIndex />,
    exact: true
  },

  {
    path: '/ambients',
    component: () => <CloudAmbients />,
    exact: true
  },
  {
    path: '/apiproduct',
    component: () => <CloudApiproduct />,
    exact: true
  },
  {
    path: '/apiconfig',
    component: () => <CloudApiconfig />,
    exact: true
  },
  {
    path: '/apiproxy',
    component: () => <CloudApiproxy />,
    exact: true
  },
  {
    path: '/gatewayManager',
    component: () => <CloudGateManager />,
    exact: true
  },

  ////
  {
    path: '/dev_teams',
    component: () => <MyTeams />,
    exact: true
  },
  {
    path: '/organizations',
    component: () => <Organizations />,
    exact: true
  },
  {
    path: '/release-manager',
    component: () => <ReleaseManager />,
    exact: true
  },
  {
    path: '/dev_organizations',
    component: () => <DevOrganizations />,
    exact: true
  }
  ,
  {
    path: '/developers_personal/logs',
    component: () => <DevLogsContent />,
    exact: true
  },
  {
    path: '/developers_personal/sandbox',
    component: () => <DevSandbox />,
    exact: true
  },
  {
    path: '/developers_personal/staging_projects',
    component: () => <DevStagingProjects />,
    exact: true
  },

  ,
  {
    path: '/developers_downloads',
    component: () => <DeveloperDownloadContent />,
    exact: true
  },
  {
    path: '/developers_downloads/downloads_downloads',
    component: () => <DownloadDownloadContent />,
    exact: true
  },

  {
    path: '/developers_downloads/downloads_repo',
    component: () => <RepoDownloadContent />,
    exact: true
  },
  {
    path: '/developers/api_test',
    component: () => <ApiTestContent />,
    exact: true
  },
  {
    path: '/developers/api_result',
    component: () => <ApiTestResult />,
    exact: true
  },
  {
    path: '/developers/api_status',
    component: () => <ApiStatus />,
    exact: true
  },

  {
    path: '/developers/logs',
    component: () => <LogsContent />,
    exact: true
  },

  {
    path: '/developers/sandbox',
    component: () => <Sandbox />,
    exact: true
  },
  {
    path: '/developers/staging_projects',
    component: () => <StagingProjects />,
    exact: true
  },
  {
    path: '/developers/apikeys',
    component: () => <ApiKeysContent />
  },
  {
    path: '/marketplace/:project',
    component: () => <MarketPlaceContent sider={DashRouters} />
  },
  {
    path: '/marketplace',
    component: () => <MarketPlaceProjects />,
  },
  {
    path: '/balance',
    component: () => <BalanceContent />
  },
  // {
  //   path: '/plan_subcription/payments',
  //   component: () => <PaymentsContent/>
  // },
  {
    path: '/cloud_billing',
    component: () => <PaymentsContent />
  },

  {
    path: '/cookies_manage',
    component: () => <CookiesManage />
  },
  {
    path: '/plan_subcription/change_payment_duration',
    component: () => <ChangePlanDurationContent sider={DashRouters} />
  },
  {
    path: '/plan_subcription',
    component: () => <PlanSubscriptionContent sider={DashRouters} />
  },
  {
    path: '/landing',
    component: () => <NewLanding />
  },
  {
    path: '/swagger',
    component: () => <SwaggerView />
  },
]

//{(props) => <Dashboard {...props} isAuthed={true} />}

// Rutas privadas, necesitas estar logueado
export const privateRoutes: Array<RouteProps> = [
  {
    path: '/private',
    component: Private,
    exact: true
  },
]



// Rutas de administrador, debe de estar logueado y ser admin TODO ver esto
export const adminRoutes: Array<RouteProps> = [
  {
    path: '/admin',
    component: () => <CreateSubcriptionContent />
  },
  {
    path: '/admin/create_subscriptions',
    component: () => <CreateSubcriptionContent />
  },
]



const getProjectsFromState = (state) => {
  if (!state) return false;
  const projects = (((state.location || {}).state || {}).state || {}).projects || false;
  return projects;
}