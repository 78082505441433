import React, { useEffect, useState } from 'react';
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';
import { useHistory, } from 'react-router-dom';
import lg_cloud from '../../assets/img/logos/AVAP_Cloud_negativo.svg'
import lg_market from '../../assets/img/logos/AVAP_Marketplace_white.svg'
import lg_publisher from '../../assets/img/logos/AVAP_API-Publisher_white.svg'
import lg_devstudio from '../../assets/img/logos/AVAP_DS_white.svg'
import lg_avs from '../../assets/img/logos/AVAP_VS_white.svg'
import lg_gateway from '../../assets/img/logos/AVAP_Gateway_white.svg'
import { Accordion, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import bg_pricing from '../../assets/img/landingPage/fondo.png'

import checkmark from '../../assets/img/landingPage/carbon_checkmark-outline.svg'
import cross from '../../assets/img/landingPage/carbon_close-outline.svg'



const Pricing = () => {



    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} >
          $25 USD month
        </Tooltip>
      );

      const renderTooltip2 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
          $99 USD month
        </Tooltip>
      );


      const renderTooltip3 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
          $45 USD month
        </Tooltip>
      );

      const renderTooltip4 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
          $75 USD month
        </Tooltip>
      );

      const renderTooltip5 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
          $45 USD month
        </Tooltip>
      );
      
      const renderTooltip6 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
          $25 USD month
        </Tooltip>
      );

      const renderTooltip7 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
         $20 USD  for the first million, $95 USD per million added
        </Tooltip>
      );


      const renderTooltip8 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
         $0,25 USD per Gb
        </Tooltip>
      );


      const renderTooltip9 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
         $250 USD month
        </Tooltip>
      );

      const renderTooltip10 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
         $10 USD API/Month
        </Tooltip>
      );

      const renderTooltip11 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
         $10 USD API/Month
        </Tooltip>
      );

      const renderTooltip12 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
        $20 USD for the first million, $95 USD per million added
        </Tooltip>
      );


      const renderTooltip13 = (props) => (
        <Tooltip id="button-tooltip" {...props} >
        $0,25 USD per Gb
        </Tooltip>
      );

    // Estado para manejar la pestaña activa
    const [activeTab, setActiveTab] = useState('cloud');
    const history = useHistory();

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav />

            {/* Contenedor principal */}
            <div id="pricing" className='fl-cloud-product' style={{ backgroundImage: `url(${bg_pricing})` }}>

                {/* Encabezado */}
                <div className="w-100 roadmapHeader text-center mb-5 noGradient">
                    <div className="fl-title">Pricing &amp; Plans</div>
                    {/* Barra de pestañas */}
                    <ul className="nav nav-pills justify-content-center pillsWrapper">
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'cloud' ? 'active' : ''}`}
                                onClick={() => setActiveTab('cloud')}
                            >
                                <img src={lg_cloud} alt="" />
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'virtual' ? 'active' : ''}`}
                                onClick={() => setActiveTab('virtual')}
                            >
                                <img src={lg_avs} alt="" />
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'gateway' ? 'active' : ''}`}
                                onClick={() => setActiveTab('gateway')}
                            >
                                <img src={lg_gateway} alt="" />
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link ${activeTab === 'devstudio' ? 'active' : ''}`}
                                onClick={() => setActiveTab('devstudio')}
                            >
                                <img src={lg_devstudio} alt="" />
                            </button>
                        </li>
                    </ul>

                </div>

                {/* Contenido que cambia según la pestaña seleccionada */}
                <div className="container  bg-black  negative-margin-top">
                    {activeTab === 'cloud' && (
                        <>
                            {/* Sección descriptiva */}
                            <div className="text-center mb-4">
                                {/*  <img src={lg_cloud} alt="" className='mt-4 mb-6' /> */}
                                <h2>A Native Management Service</h2>
                                <p>
                                    AVAP Cloud is a powerful API platform that manages the entire API lifecycle in a SaaS model, from design to deployment.
                                    Powered by AVAP technology, it integrates virtuality and AI into every API and API product you develop.

                                </p>
                            </div>

                            {/* Sección de planes */}

                            <div className="row">
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        {/* <img src={lg_cloud} alt="" /> */}
                                        <div className="titleBox alter">
                                            AVAP CLOUD PROFESSIONAL
                                        </div>
                                        <div>
                                            <p className="price">
                                                $75.00
                                            </p>
                                            <span className='priceInfo'>Per month</span>
                                        </div>

                                        <div className="descBox">
                                            Membership that allows you to explore and start taking advantage of AVAP technology.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://avapcloud.com/professional_en"><span className="btnInner">  Start for Free</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">

                                        <div className="titleBox alter">
                                            AVAP CLOUD BUSINESS
                                        </div>
                                        <div>
                                            <p className="price">
                                                $250.00
                                            </p>
                                            <span className='priceInfo'>Per month</span>
                                        </div>
                                        <div className="descBox">
                                            Pre-configured AVAP infrastructure deployment, enabling you to concentrate on developing and publishing your APIs
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href=" https://avapcloud.com/business_en"><span className="btnInner">  Start for Free</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        {/* <img src={lg_cloud} alt="" /> */}
                                        <div className="titleBox alter">
                                            AVAP CLOUD ENTERPRISE
                                        </div>
                                        <div>
                                            <p className="price">
                                                Custom Pricing
                                            </p>
                                            <span className='priceInfo'>Billed Annually</span>
                                        </div>
                                        <div className="descBox">
                                            Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' onClick={() => history.push('/contact')}><span className="btnInner"> Contact Us</span></a>
                                    </div>
                                </div>
                            </div>



                            {/* Sección de Deployment Features */}
                            <div className="mt-6 mb-5 row">
                                <div className="col">
                                    <h3 className="text-center mb-4">Deployment Features</h3>
                                </div>
                            </div>
                            <div className="row">

                                <div className="divTable pricingTable">
                                    <div className="divTableBody">

                                        {/* Fila 1: Encabezados */}
                                        <div className="divTableRow">
                                            <div className="divTableCell relative">
                                                {/* <img className='logoTable' src={lg_cloud} alt="AVAP Cloud" /> */}
                                                <span className="bold">AVAP Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Professional</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Business</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Enterprise</span>
                                            </div>
                                        </div>

                                        {/* Fila 2: Descripción */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    
                                                </span>
                                            </div>
                                        </div>

                                        {/* Fila 3: Gateway Services */}
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className="">AVAP Gateway Advanced
                                                </span>
                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 5</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 4: Organizations */}
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className="tableTitle">Gateway Services
                                                </span>
                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip2}
                                                >
                                                    <span style={{cursor: 'pointer', textAlign:'end' }}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 20</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 5: API Requests */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Custom Domain</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 6: Projects */}
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className="tableTitle">Ambients (Live)
                                                </span>

                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip3}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon  icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>

                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 20</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 7: Developers */}
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>AVAP Virtual Server

                                                </span>

                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip4}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>

                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 10</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 8: Request */}
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>Enviroments (Dev)
                                                </span>

                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip5}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="divTableCell">
                                                <span>3</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 5</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 9: Storage */}
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>AVAP Language Server
                                                </span>

                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip6}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>


                                            </div>
                                            <div className="divTableCell">
                                                <span>3</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 15</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 10: Lorem ipsum */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Organizations</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>{/*<img src={cross} alt="No" />*/}1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 11: Lorem ipsum */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>API Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 10</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        {/* Fila 12: Lorem ipsum */}
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Users</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                {/* <img className='logoTable' src={lg_publisher} alt="AVAP Cloud" /> */}
                                                <span className="">Teams</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>

                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>Requests
                                                </span>

                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip7}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>

                                            </div>
                                            <div className="divTableCell">
                                                <span>1M</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                {/*       <img className='logoTable' src={lg_market} alt="AVAP Cloud" /> */}
                                                <span className="">Storage
                                                </span>

                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip8}
                                                >
                                                    <span style={{cursor: 'pointer', textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>


                                            </div>
                                            <div className="divTableCell">
                                                <span>1024 Mb</span>
                                            </div>

                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>AVAP Marketplace</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>IP Filtering</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>




                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Multi-Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>WAF & DDoS Protection</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Auto-Scaling</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>Developer Portal
                                                </span>
                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip9}
                                                >
                                                    <span style={{cursor: 'pointer', textAlign:'end' }}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 5</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>Published API</span>
                                                {/*}
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip10}
                                                >
                                                    <span style={{cursor: 'pointer', textAlign:'end' }}> 
                                                    <FontAwesomeIcon icon={faInfoCircle} /> 
                                                    </span>
                                                </OverlayTrigger>*/}
                                            </div>
                                            <div className="divTableCell">
                                                <span>$5 USD API/Month</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$5 USD API/Month</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$5 USD API/Month</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>Published Virtual API</span>
                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip11}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="divTableCell">
                                                <span>50 API included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>100 API included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>250 API included</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>API Wrapped</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>API Product Wrapped</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Basic Analytics</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>AVAP Vision</span>
                                                <div>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip12}
                                                >
                                                    <span style={{cursor: 'pointer', textAlign:'end' }}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Up to 10M</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>AVAP Mesh Manager</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$299 USD month</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Logic Infraestructure per Organization</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="Yes" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell" style={{display:'flex'}}>
                                                <span className='tableTitle'>Retention Data</span>
                                                <OverlayTrigger
                                                    placement="top"  // Puedes ajustar la posición del tooltip
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltip13}
                                                >
                                                    <span style={{cursor: 'pointer' , textAlign:'end'}}> {/* Espacio y cursor */}
                                                    <FontAwesomeIcon icon={faInfoCircle} /> {/* Icono de información */}
                                                    </span>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="divTableCell">
                                                <span>3 months</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>12 months</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Unlimited</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Technical Support</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>AVAP Community</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>eMail (1 hour)</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>24x7 eMail</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>SLA</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>-</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>99.9%</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>99.9%</span>
                                            </div>
                                        </div>
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Training</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>AVAP University</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>AVAP University</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>AVAP University</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {activeTab === 'virtual' && (
                        <>
                            {/* Sección descriptiva */}
                            <div className="text-center mb-4">
                                {/*     <img src={lg_avs} alt="" className='mt-4 mb-6' /> */}
                                <h2>AVAP Virtual Server</h2>
                                <p>
                                AVAP Virtual Server (AVS) streamlines API lifecycle management with version control, testing, and deployment. Built on Node.js and KOA.js, it ensures scalability, intelligent traffic redirection, and compatibility with all programming languages and API servers for maximum flexibility and performance.

                                </p>
                            </div>

                            {/* Sección de planes */}
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            STANDALONE 
                                        </div>
                                        <div>
                                            <p className="price">
                                                FREE
                                            </p>
                                            <span className='priceInfo'></span>
                                        </div>
                                        <div className="descBox">
                                        Local deployment with its own database, ideal for learning, testing, and using AVAP tools, like Mesh Manager and Commander.
                                        </div>
                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://avapid.com">
                                            <span className="btnInner">Start for Free</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            COLLABORATIVE EDITION
                                        </div>
                                        <div>
                                            <p className="price">
                                                FREE
                                            </p>
                                            <span className='priceInfo'></span>
                                        </div>
                                        <div className="descBox">
                                        Designed for shared networks, enabling learning, collaboration, resource sharing, and openness for external API requests.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://avapid.com"><span className="btnInner">  Start for Free</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            ENTERPRISE EDITION
                                        </div>
                                        <div>
                                            <p className="price">
                                                Custom Pricing
                                            </p>
                                            <span className='priceInfo'>Billed Annually</span>
                                        </div>
                                        <div className="descBox">
                                        On-premise and hybrid AVS, integrates databases, manages ACLs, and structures architecture with AVAP Mesh Manager.
                                        </div>

                                        <a onClick={()=>{history.push('/contact')}} className="glowBtn-dark alter m-0" /*target='_blank' href="https://developer.avapframework.com"*/><span className="btnInner"> Contact Us</span></a>
                                    </div>
                                </div>
                            </div>


{/*}
                            
                            <div className="mt-6 mb-5 row">
                                <div className="col">
                                    <h3 className="text-center mb-4">Deployment Features</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="divTable pricingTable">
                                    <div className="divTableBody">

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell relative">
                                                
                                                <span className="bold">AVAP Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>On Premise</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Hybrid</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="bold">Gateway Services</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Organizations</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">API Requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Request</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Storage</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                               
                                                <span className="bold">AVAP Publisher</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>

                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                
                                                <span className="bold">AVAP Marketplace</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>

                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Publicación API Products</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Comercialización</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div> */}
                        </>
                    )}

                    {activeTab === 'gateway' && (
                        <>
                            {/* Sección descriptiva */}
                            <div className="text-center mb-4">
                                {/*   <img src={lg_gateway} alt="" className='mt-4 mb-6' /> */}
                                <h2>AVAP Gateway Advanced</h2>
                                <p>
                                AVAP Gateway Advanced is a high-performance, LUA-powered solution that analyzes traffic patterns to optimize routing. With TLS 1.3 security, seamless multi-language compatibility, and a scalable dockerized platform, it ensures efficient API traffic management, enhanced performance, and flexible deployment
                                </p>
                            </div>

                            {/* Sección de planes */}
                            <div className="row">
                                <div className="col-md-4">
                                    {/*}
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            AVAP GATEWAY PROFESSIONAL
                                        </div>
                                        <div>
                                            <p className="price">
                                                $00.00
                                            </p>
                                            <span className='priceInfo'>Per user/month</span>
                                        </div>
                                        <div className="descBox">
                                            Membership that allows you to explore and start taking advantage of AVAP technology.
                                        </div>
                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://developer.avapframework.com">
                                            <span className="btnInner">Start for Free</span>
                                        </a>
                                    </div>
                                    */}
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                        AVAP GATEWAY ENTERPRISE
                                        </div>
                                        <div>
                                            <p className="price">
                                            Custom Pricing
                                            </p>
                                            <span className='priceInfo'></span>
                                        </div>
                                        <div className="descBox">
                                        On-premise centralized API exposure point, managing traffic and routing between services based on defined logic.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" onClick={()=>{history.push('/contact')}} /*target='_blank' href="https://developer.avapframework.com"*/><span className="btnInner">  Contact US</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    {/*}
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            AVAP GATEWAY ENTERPRISE
                                        </div>
                                        <div>
                                            <p className="price">
                                                Custom Pricing
                                            </p>
                                            <span className='priceInfo'>Billed Annually</span>
                                        </div>
                                        <div className="descBox">
                                            Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://developer.avapframework.com"><span className="btnInner"> Contact Us</span></a>
                                    </div>
                                    */}
                                </div>
                            </div>

{/*}
                           
                            <div className="mt-6 mb-5 row">
                                <div className="col">
                                    <h3 className="text-center mb-4">Deployment Features</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="divTable pricingTable">
                                    <div className="divTableBody">

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell relative">
                                               
                                                <span className="bold">AVAP Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>On Premise</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Hybrid</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="bold">Gateway Services</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Organizations</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">API Requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Request</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Storage</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                     
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                         
                                                <span className="bold">AVAP Publisher</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>

                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                             
                                                <span className="bold">AVAP Marketplace</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>

                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Publicación API Products</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Comercialización</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                    </div>
                                </div> 
                            </div>*/}
                        </>
                    )}

                    {activeTab === 'devstudio' && (
                        <>
                            {/* Sección descriptiva */}
                            <div className="text-center mb-4">
                                {/*   <img src={lg_gateway} alt="" className='mt-4 mb-6' /> */}
                                <h2>AVAP DEV STUDIO</h2>
                                <p>
                                AVAP extensions for Microsoft Visual Studio streamline building, testing, and managing Virtual APIs with powerful features.
                                </p>
                            </div>

                            {/* Sección de planes */}
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            API EXTENSION FOR MSVC
                                        </div>
                                        <div>
                                            <p className="price">
                                                FREE
                                            </p>
                                            <span className='priceInfo'></span>
                                        </div>
                                        <div className="descBox">
                                            Membership that allows you to explore and start taking advantage of AVAP technology.
                                        </div>
                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://marketplace.visualstudio.com/search?term=avap&target=VSCode&category=All%20categories&sortBy=Relevance">
                                            <span className="btnInner">Start for Free</span>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            PROFESSIONAL EDITION
                                        </div>
                                        <div>
                                            <p className="price">
                                                $9.95
                                            </p>
                                            <span className='priceInfo'>Per user/month</span>
                                        </div>
                                        <div className="descBox">
                                        IDE with advanced tools for individual developers, streamlining Virtual API development and deployment via credential files.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" target='_blank' href="https://avapid.com"><span className="btnInner">  Start for Free</span></a>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="CloudCard gradient-d">
                                        <div className="titleBox alter">
                                            ENTERPRISE EDITION
                                        </div>
                                        <div>
                                            <p className="price">
                                                Custom Pricing
                                            </p>
                                            <span className='priceInfo'>Billed Annually</span>
                                        </div>
                                        <div className="descBox">
                                        Scalable IDE designed for corporations, enhancing team collaboration in Virtual API development and deployment management.
                                        </div>

                                        <a className="glowBtn-dark alter m-0" onClick={()=>{history.push('/contact')}}/*target='_blank' href="https://developer.avapframework.com"*/><span className="btnInner"> Contact Us</span></a>
                                    </div>
                                </div>
                            </div>

{/*}
                           
                            <div className="mt-6 mb-5 row">
                                <div className="col">
                                    <h3 className="text-center mb-4">Deployment Features</h3>
                                </div>
                            </div>
                            <div className="row">
                                <div className="divTable pricingTable">
                                    <div className="divTableBody">

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell relative">
                                               
                                                <span className="bold">AVAP Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>On Premise</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Cloud</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span className='bold'>Hybrid</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>
                                                    Lorem ipsum dolor sit amet, consectetur sadipscing elitr,
                                                    sed diam nonumy eirmod tempor invidunt ut labore.
                                                </span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="bold">Gateway Services</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Organizations</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">API Requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1 Slots</span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className="tableTitle">Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>4 Projects</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>2 Developers</span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Request</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>$20 for the first 1M API requests</span>
                                            </div>
                                        </div>

                                        
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Storage</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>1GB</span>
                                            </div>
                                        </div>

                                       
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                      
                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                        
                                                <span className="bold">AVAP Publisher</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>

                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Lorem ipsum</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={cross} alt="No" /></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span><img src={checkmark} alt="Yes" /></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                           
                                                <span className="bold">AVAP Marketplace</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>

                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                            <div className="divTableCell">
                                                <span></span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Publicación API Products</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                        <div className="divTableRow">
                                            <div className="divTableCell">
                                                <span className='tableTitle'>Comercialización</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                            <div className="divTableCell">
                                                <span>Included</span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>*/}
                        </>
                    )}
                </div>

                {/* Sección FAQ */}
                <div className="container mt-8 mb-6" id="faq">
                    <h3 className="text-center mb-4 fl-title">FAQ</h3>

                    <Accordion defaultActiveKey="0" className='blackTheme'>
                        <div className="divider"></div>
                        {/* Pregunta 1 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>What membership options are available in AVAP Cloud?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    AVAP Cloud offers three tiers: Professional (for individual developers),
                                    Business (for SMEs) and Enterprise (for large corporations needing hybrid
                                    network capabilities).
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 2 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>What payment methods are accepted?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    AVAP Cloud currently supports credit card payments in USD for Professional
                                    and Business plans. Enterprise customers receive a personalized plan
                                    tailored to their needs.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 3 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>What are the subscription terms?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body>
                                    Subscriptions are available on a monthly or annual basis.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 4 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>How does AVAP Cloud manage API infrastructure?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>
                                    AVAP Cloud handles API execution, traffic routing, and gateway management,
                                    so you can focus on development without managing servers.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 5 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>What are the benefits of the Enterprise membership?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                    Enterprise includes hybrid network capabilities, enabling businesses to
                                    combine cloud and on-premise resources for data, process, performance
                                    and security.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 6 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>Can I deploy APIs in AVAP Cloud without managing servers?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="5">
                                <Card.Body>
                                    Yes, AVAP Cloud provides a fully managed environment, eliminating the
                                    need for server maintenance.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 7 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>What development tools are compatible with AVAP Cloud?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="6">
                                <Card.Body>
                                    AVAP Cloud supports AVAP Dev Studio and Microsoft Visual Studio Code
                                    with AVAP extensions.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>

                        {/* Pregunta 8 */}
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <span>Where can I find documentation and support?</span>
                                        <FontAwesomeIcon icon={faChevronDown} className='text-white' />
                                    </div>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="7">
                                <Card.Body>
                                    Documentation is available at AVAP Alexandria, and community support
                                    is provided via AVAP Communities.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <div className="divider"></div>
                    </Accordion>

                </div>
            </div>

            <FlFooter />
        </>
    );
};

export default Pricing;
