import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/gateway/Gateway_01.png'
import features_2 from '../../assets/img/landingPage/gateway/Gateway_02.png'
import features_3 from '../../assets/img/landingPage/gateway/Gateway_03.png'



import logo from '../../assets/img/logos/AVAP_Gateway_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">An intelligent gateway designed for <br /> performance and security</h1>
                    <p className="fl-subtitle">
                        More powerful than a proxy
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                                Smarter, Powerful Gateway for <br /> Optimized API Traffic


                            </p>
                            <p className="fl-text">
                                AVAP Gateway Advanced is a high-performance, intelligent solution that goes beyond basic proxy functionality. Powered by LUA programming, it intelligently assesses traffic consumption patterns, history, and set parameters to dynamically redirect to optimal servers. With seamless compatibility across all language servers, it enables flexible and scalable routing. Offering high-level security with TLS 1.3 and a dockerized platform for easy scaling, AVAP Gateway Advanced ensures efficient API traffic management, optimized performance, and enhanced security.
                            </p>
                        </div>
                    </div>

                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Virtual Proxy Management and Scalability</h3>
                            <p className="fl-text">
                                AVAP Gateway Advanced enables the management of virtual proxies, allowing for the creation and customization of multiple proxy instances. This flexibility ensures optimized routing, load balancing, and resource allocation across different environments.
                            </p>
                        </div>

                    </div>
                </section>


                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Intelligent Traffic Routing</h3>
                            <p className="fl-text">
                                AVAP Gateway Advanced uses advanced algorithms to assess API consumption patterns, historical data, and real-time parameters to make intelligent decisions on traffic redirection. It goes beyond simple proxy functionality by dynamically adjusting routing paths based on current system load, server performance, and external conditions.


                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Flexible and Scalable Architecture</h3>
                            <p className="fl-text">
                                The gateway supports flexible configurations, including the nesting of multiple instances, to handle complex routing requirements. It is designed to be easily scalable, thanks to its dockerized structure, allowing businesses to deploy additional instances of the gateway as needed. Whether scaling vertically to increase capacity or horizontally to add more instances.

                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>



                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Optimized API Performance
                                </p>
                                <p className="fl-text">
                                    Intelligent traffic routing reduces latency and ensures that requests are directed to the most efficient servers, improving overall performance.

                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Seamless Scalability
                                </p>
                                <p className="fl-text">
                                    The dockerized infrastructure enables effortless scaling, adapting to changing traffic loads without disruption.
                                    <br />&nbsp;

                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Security and Reliability
                                </p>
                                <p className="fl-text">
                                    Robust encryption methods and secure protocols, combined with advanced routing capabilities, ensure secure redirection, enhance system reliability, and minimize downtime across all environments.

                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
