import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/virtualServer/VirtualServer_01.png'
import features_2 from '../../assets/img/landingPage/virtualServer/VirtualServer_02.png'
import features_3 from '../../assets/img/landingPage/virtualServer/VirtualServer_03.png'




import logo from '../../assets/img/logos/AVAP_VS_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'



const VersionControlProduct = () => {
    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Redefining API Virtual Management</h1>
                    <p className="fl-subtitle">
                        Lightweight scalability, virtual and with universal compatibility
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                                Efficient API Management with Virtualization
                            </p>
                            <p className="fl-text">
                                AVAP Virtual Server (AVS) enhances API management by integrating with AVAP Cloud's repository for version control, testing, and deployment. Built on Node.js and KOA.js, AVS provides a lightweight, scalable platform that utilizes virtualization for seamless API exposure and management. It supports all programming languages and API servers, offering flexibility and control throughout the development process.
                            </p>
                        </div>
                    </div>

                </section>



                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">API Wrapped for Seamless Protocol Adaptation</h3>
                            <p className="fl-text">
                                AVS includes API Wrapped functionality, allowing seamless adaptation of APIs between different protocols such as REST, SOAP, GraphQL, and more. This ensures flexibility in integrating with various external systems and services without altering the core API logic.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Virtual API Version Control and Management</h3>
                            <p className="fl-text">
                                AVS manages virtual API versions without directly hosting the APIs. It provides the necessary tools for thorough testing and version control, ensuring reliable deployment across environments and minimizing errors during both testing and production phases.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Universal Product</h3>
                            <p className="fl-text">
                                It is designed to work with AVAP language servers and supports a wide range of API servers, including NODE, Java, Python, and others. This universal compatibility ensures that your existing APIs integrate effortlessly with AVS, allowing developers to continue working with the tools they are familiar with while benefiting from AVAP’s advanced capabilities, and the user experience by handling varying loads and adapting to traffic demands.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Efficient Execution of APIs
                                </p>
                                <p className="fl-text">
                                    AVS optimizes the execution of virtual APIs, ensuring that performance remains high while reducing resource consumption, improving overall efficiency across environments.
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Testing Flexibility
                                </p>
                                <p className="fl-text">
                                    With integrated testing tools, AVS provides robust testing capabilities for virtual APIs, ensuring functionality and performance across multiple environments before deployment.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Sustainable and Effortless Integration
                                </p>
                                <p className="fl-text">
                                    AVS combines low energy consumption with an easy installation process, making it a cost-effective and sustainable solution for managing APIs.
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                    <br /> &nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
